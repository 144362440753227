import React from "react";

const Resume = ({ data }) => {
  if (data) {

    var education = data.education.map(function (education) {
      return (
        <div key={education.school}>

          <p className="info">
            {education.degree}
            <span>&bull;</span>{education.subject}
            <span>&bull;</span>
            {education.school}
            {/* <span>&bull;</span> */}
            {/* <em className="date">{education.graduated}</em> */}
          </p>
          {/* <p>{education.description}</p> */}
        </div>
      );
    });
    var work = data.work.map(function (work) {
      return (
        <>
          <div key={work.company} class='work-row'>

            <p className="info">
              <span className="title">{work.title}
              </span>  <span>&bull;</span><em className="date">{work.company}</em>
              <span>&bull;</span> <em className="date">{work.years}</em>
            </p>

            <ul className="description">{work.description.split(";").map(des => <li key={des}>{des}</li>)}</ul>
            {work.stack?.split(',').length > 0 &&
              <p className="stacks"> {work.stack?.split(',').map(stack => <span key={stack} class="stack">&bull; {stack} </span>)}</p>}
          </div>

        </>
      );
    });

  }

  return (

    <section id="experience">
      <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>Experience</span>
          </h1>
        </div>

        <div className="nine columns main-col">{work}</div>
      </div>

      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default Resume;
